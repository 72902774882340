html, body{
	min-height:100%;
	position: relative;
	margin:0 !important;
	font-weight: 400;
	font-size: @font-size-base;
}
a, .btn, button, .myanimated{
	.transition(all 300ms ease);
} 
h1{
    margin:0 0 1em 0;
    padding:0;
    color:@black;
    
}
h2, h3, h4, h5{
    margin:1em 0 0.5em 0;
    padding:0;
    color:@black;
    
}
.block-title{
	margin:0 0 1em 0;
	font-size:@font-size-h3;
	text-align: center;
}
#admin-menu{
	font-size:12px;
	display:none;
	@media @tablet{
		display:block;
	}
}
body.admin-menu{
	margin-top:0!important;
	@media @tablet{
		margin-top:29px!important;
	}
}
body.noscroll{
	position: fixed;
	left:0;
	top:0;
	width:100%;
	@media @normal{
		position: static;
	}
}
.btn{
	padding:0.5em 1em;
	background: @gray-lighter;
	border:0;
	font-weight: 600;
	color:@text-color;
	.border-radius(0.5em);
	line-height: 1em;
	&:hover{
		background:@gray-light;
		color:@text-color;
	}
}
	&.bluebtn{
		background: @theme-color-2;
		color:@white;
		&:hover{
			background:lighten(@theme-color-2, 10%);
			color:@white;
		}
	}
	&.redbtn{
		background: @theme-color-1;
		color:@white;
		&:hover{
			background:darken(@theme-color-1, 10%);
			color:@white;
		}
	}
	&.bigbtn{
		padding:0.75em 2em;
		font-size: @font-size-large;
	}
.blue{
	color:@theme-color-1;
}
.underlined{
	position: relative;
	padding-bottom:1rem;
	&:after{
		height:1px;
		width:40px;
		position:absolute;
		left:50%;
		bottom:0;
		margin-left:-20px;
		content:'';
		display: block;
		background: @theme-color-2;
	}
}