@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700');

// Bootstrap library.
@import 'bootstrap';

// Base-theme overrides.
@import 'overrides';

// Base-theme overrides.
@import 'variable-overrides';

// Additional mixins.
@import 'user-mixins';

// Material design colors
@import '_colors';

// Styles for used elements
@import 'elements';

.overlay {
  display: none;

  &.shadow {
    top: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.33);
    z-index: 1000;

    @media @normal {
      display: none;
    }
  }
}

html {
  scroll-behavior: smooth;
}

.header {
  color: @text-color;
  display: block;
  position: fixed;
  width: 100%;
  min-height: 100%;
  z-index: 1001;
  left: -100%;
  top: 0;
  padding: 0;
  .myanimated;

  @media @normal {
    position: sticky;
    .animate__animated;
  }

  .header_wrapper {
    .transition(all 300ms ease);
    position: absolute;
    width: calc(~'100% - 40px');
    height: 100%;
    left: -100%;
    background: @gray-lightest;
    overflow-y: scroll;
    z-index: 10000;
    .myanimated;
  }

  .close {
    .transition(all 300ms ease);
    color: @theme-color-1;
    font-size: 16pt;
    width: 40px;
    height: 40px;
    background: @gray-lightest;
    position: absolute;
    top: 0;
    right: 100%;
    padding: 4px 10px;
    z-index: 10001;
    .box-shadow(inset 0px 0px 5px rgba(0, 0, 0, 0.5));
    .text-shadow(none);
    cursor: pointer;
    opacity: 1;
    font-weight: normal;
    padding-top: 0.35em;
  }

  .clickme {
    position: absolute;
    z-index: 9998;
    height: 100%;
    width: 100%;
  }

  &.open {
    left: 0;
    top: 0;

    .header_wrapper {
      left: 0;
      .box-shadow(0 0 35px rgba(0, 0, 0, 0.75));
    }

    .close {
      right: 0;
    }
  }

  .topbar {
    .myanimated;

    .logo {
      padding: 1em 0;
      margin: 0 0 0 0;
      border-bottom: 1px solid @gray-more-light;
      text-align: center;
      .myanimated;

      img {
        max-width: 240px;
        width: 100%;
        height: auto;
        .myanimated;
      }
    }

    .topcontacts {
      text-align: center;
      padding: 1em 0;

      p {
        margin: 0;
        line-height: 1.4;

        &.bigphone {
          font-size: @font-size-h2;
          font-weight: 700;
        }

        a {
          color: @theme-color-1;
        }
      }
    }

    .topcart {
      padding: 1em 0;
    }
  }

  .topbarmenu {
    background: @gray-mid-dark;

    .mainmenu {
      padding: 0.25em 0;
      font-size: @font-size-small;

      .nav {
        padding: 0;
        margin: 0;

        li {
          display: block;

          a {
            display: block;
            margin: 0;
            padding: 0.5em 0;
            background: none;
            color: @white;

            &:hover {
              text-decoration: underline;
              color: @white;
            }

            &.active {
              color: @white;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .topmenu {
    .myanimated;
    background: @theme-color-1;
    color: @white;

    .mainmenu {
      padding: 1em 0;

      .nav {
        padding: 0;
        margin: 0;

        li {
          display: block;

          a {
            display: block;
            margin: 0;
            padding: 0.5em 0;
            background: none;
            border-bottom: 1px solid lighten(@theme-color-1, 10%);
            color: @white;

            &:hover {
              text-decoration: underline;
              color: @white;
            }

            &.active {
              color: @white;
              text-decoration: underline;
            }

            &.btn {
              border: 0;
              background: @white;
              padding: 0.5em;
              margin-top: 1em;
              .border-radius(0);
              color: @theme-color-1;

              &:hover {
                color: @theme-color-1;
                .box-shadow(0 0 10px @light-shadow);
              }
            }
          }

          &.dropdown {
            .dropdown-menu {
              display: block;
              border: none;
              .border-radius(0);
              padding: 0.25em 1em;
              margin: -1px 0 0 0;
              position: relative;
              float: none;
              .box-shadow(none);
              background: none;

              li {
                a {
                  padding: 0.25em 0;
                  border-bottom: none;
                  color: #fff;
                  text-transform: none;
                }
              }

              @media @normal {
                li {
                  a {
                    color: @gray-base;
                    border-radius: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .topsearch {
      padding: 1px 0;

      .form-group {
        margin: 0;
      }

      input,
      button {
        height: 29px;
        border: none;
        padding: 1px 0.5rem;
      }
    }
  }

  .topcarmenu {
    display: none;
    background: @white;
    color: @theme-color-2;
    .myanimated;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0 0.5em;
        display: inline-block;
        color: #333;

        a {
          color: @theme-color-2;
        }
      }
    }
  }

  @media @tablet {
    .topbar {
      .logo {
        margin: 0;
        border-bottom: none;
      }
    }
  }

  @media @normal {
    /*display:none;*/
    z-index: 100;
    //position: fixed;
    width: 100%;
    min-height: auto;
    left: 0;
    color: @gray-base;
    border-bottom: 1px solid @gray-light;
    background: @white;

    .header_wrapper {
      position: relative;
      width: 100%;
      height: auto;
      left: 0;
      .clearfix;
      overflow-y: visible;
      background: none;
    }

    &:after {
      display: none;
    }

    .close {
      display: none;
    }

    .clickme {
      display: none;
    }

    .topbar {
      .clearfix;

      .logo {
        margin: 1.5em 0 0 0;
        padding: 0 @grid-gutter-width / 2 0 0;
        border-top: none;
        border-bottom: none;
        text-align: left;
        .myanimated;

        img {
          max-width: 600px;
          width: 90%;
          .myanimated;
        }
      }

      .topcontacts {
        text-align: left;
        padding: 1em 0;
        .myanimated;

        p {
          line-height: 1.2;

          &.bigphone {
            font-size: @font-size-h2;
          }
        }
      }
    }

    .topmenu {
      padding: 0.5em 0;
      .clearfix;

      .mainmenu {
        padding: 0;

        .nav {
          text-align: left;

          li {
            display: inline-block;

            a {
              display: block;
              margin: 0;
              padding: 0 0.5em;
              background: none;
              border-bottom: none;
              .border-radius(3px);

              &.btn {
                padding: 1em;
                margin-top: 0;
              }
            }

            &:first-child {
              a {
                padding: 0 0.5em 0 0;
              }
            }

            &.dropdown {
              .dropdown-menu {
                position: absolute;
                float: left;
                display: none;
                .box-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
                padding: 1em;
                background: @white;

                li {
                  display: block;

                  a {
                    padding: 0.25em 0;
                    border-bottom: none;

                    &.active {
                      background: none;
                      color: @theme-color-1;
                    }

                    &:hover {
                      color: @theme-color-1;
                    }
                  }
                }
              }

              &:hover {
                .dropdown-menu {
                  display: block;
                }
              }
            }
          }
        }

        &.usermenu {
          .nav {
            text-align: right;
          }
        }
      }
    }

    .topbarmenu {
      padding: 0.25em 0;
      .clearfix;

      .mainmenu {
        padding: 0;

        .nav {
          text-align: left;

          li {
            display: inline-block;

            a {
              display: block;
              margin: 0;
              padding: 0 0.5em;
              background: none;
              border-bottom: none;
              .border-radius(3px);

              &.btn {
                padding: 1em;
                margin-top: 0;
              }
            }
          }
        }

        &.usermenu {
          .nav {
            text-align: right;
          }
        }
      }
    }

    .topcarmenu {
      .myanimated;
      display: block;
      padding: 0.5em 0;

      ul {
        text-align: left;

        li {
          display: inline-block;
          margin: 0;
          padding: 0 0.5em;
          border-right: 1px solid @gray-more-light;

          &:first-child {
            padding: 0 0.5em 0 0;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    &.collapsed {
      //.animate__fadeIn;

      .topcarmenu {
        opacity: 0;
        visibility: hidden;
        z-index: -100;
        padding: 0;
        height: 1px;
        margin-top: -1px;
        overflow: hidden;
      }

      .topbar {
        .logo {
          margin: 0.75em 0 0 0;

          img {
            max-width: 300px;
          }
        }

        .topcontacts {
          padding: 0.5em 0;

          p {
            line-height: 1;

            &.bigphone {
              font-size: @font-size-large;
            }
          }
        }
      }
    }
  }

  @media @wide {
    .topbar {
      .topcontacts {
        p {
          &.bigphone {
            font-size: @font-size-h1;
          }
        }
      }
    }

    .topmenu,
    .topcarmenu {
      font-size: @font-size-large;

      ul {
        li {
          padding: 0 0.25em;

          &:first-child {
            padding: 0 0.5em 0 0;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }

  @media @xwide {
    .topbar {
      .logo {
        margin: 1.25em 0 0 0;
      }
    }

    .topmenu,
    .topcarmenu {
      font-size: @font-size-h4;
    }
  }
}

.mobile_header {
  position: sticky;
  display: block;
  padding: 1em @grid-gutter-width / 2;
  background: @gray-lightest;
  color: @white;
  vertical-align: middle;
  top: 0;
  z-index: 100;
  width: 100%;
  .box-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));

  @media @normal {
    display: none;
  }

  .m_header {
    display: flex;
    flex-direction: row;
    align-items: center;

    > .header-title {
      flex: 1;
    }

    > .mobile-top-header {
      flex: 0 0 auto;
      > .region {
        > .header-social-media {
          margin-top: 0;
          margin-bottom: 0;
          .sidebar-social-media {
            a {
              background-color: @theme-color-1;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .menu-btn {
    position: relative;
    width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    color: @theme-color-1;
    margin-left: auto;
    font-size: 18pt;
    font-weight: normal;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 0.25em 0;
    //right: @grid-gutter-width / 2;
    //top: 0.25em;
  }

  .header-title {
    display: inline-block;
    line-height: 1;
    padding: 0;

    img {
      width: auto;
      height: 20px;
    }
  }
}

.footer,
.copy {
  padding: 3em 0 1em 0;
  margin: 0;
  position: relative;
  background: @theme-color-2;
  color: @white;

  .container {
    background: none;
    position: relative;
    z-index: 1000;
  }

  .footer-block {
    padding-top: 0;
    margin-bottom: 1em;

    @media @tablet {
      padding-top: 0;
    }

    h2,
    h3 {
      margin: 0 0 1em 0;
      text-align: left;
      text-transform: uppercase;
      font-size: @font-size-h4;
      font-weight: 700;
      color: @white;
    }

    a {
      color: @white;

      &:hover {
        color: @white;
        text-decoration: underline;
      }
    }

    .fa {
      font-weight: normal;
      color: @gray-light;

      &:hover {
        color: @theme-color-1;
        text-decoration: none;
      }
    }

    .nav {
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 0;
        display: block;

        a {
          margin: 0;
          padding: 0 0 0.5em 0;
          background: none;
          color: @white;

          &:hover {
            color: @white;
            text-decoration: underline;
          }
        }
      }
    }

    &.social {
      .fa {
        width: 3rem;
        height: 3rem;
        font-size: @font-size-h2;
        color: @white;
        border: 1px solid @gray-more-light;
        text-align: center;
        padding-top: 0.35em;
        .border-radius(2rem);
        margin: 0 0 0 0.5em;
        .myanimated;

        &:hover {
          background: @theme-color-3;
          color: @white;
          border-color: @theme-color-3;
          .box-shadow(0 0 15px rgba(0, 0, 0, 0.25));
        }
      }
    }

    &.contact-block {
      border-top: 1px solid @gray-more-light;
      padding-top: 2em;

      @media @wide {
        border-top: none;
        padding-top: 0;
      }
    }

    &.signup {
      input {
        height: 36px;
        border: none;
        .border-radius(3px);
        width: 75%;
        background: lighten(@theme-color-2, 10%);
        color: @white;
        padding: 0 0.5em;
        .placeholder-color(lighten(@theme-color-2, 25%));
      }

      button {
        height: 36px;
        border: none;
        .border-radius(3px);
        width: 24%;
        margin-left: 1%;
        background: darken(@theme-color-2, 10%);
        color: @white;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }
}

.copy {
  background: darken(@theme-color-2, 10%);
  padding: 1em 0;

  a {
    color: @white;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0 3em 0 0;
      display: inline-block;

      a {
        color: @white;
      }
    }
  }
}

.content {
  padding: 0;

  > .container {
    padding-top: 2em;
    padding-bottom: 4em;

    @media @normal {
      padding-top: 4em;
      padding-bottom: 6em;
    }
  }
}

.content-col {
  padding: 0;

  @media @normal {
    padding: 0 @grid-gutter-width / 2 0 @grid-gutter-width;
  }

  h2 {
    font-size: 18pt;
    margin: 0 0 15px 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.hp_search {
  position: relative;

  .container {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .search-bg {
    position: relative;
    height: 100%;
    width: 100%;

    .bx-viewport {
      height: auto !important;
    }

    .bx-wrapper {
      position: relative;
      width: 100%;

      .bx-controls-direction {
        a {
          z-index: 10;
        }
      }

      ul {
        position: relative;
        list-style: none;
        > li {
          position: relative;
          > a {
            display: flex;
            position: relative;
            width: 100% !important;
            height: 100% !important;
            top: 0;
            left: 0;
            padding-bottom: 100%;
            overflow-y: hidden;

            > img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100% !important;
              height: 100% !important;
            }

            > .mobile-only {
              object-fit: contain;
              background-color: #fff;
            }

            @media all and (min-width: 580px) {
              padding-bottom: 30%;
            }
          }
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .search-text {
    color: @white;
    font-weight: 700;
    text-align: center;
    font-size: @font-size-h4;
    margin-top: 0.5em;

    @media @tablet {
      margin-top: 8rem;
      font-size: @font-size-h3;
      text-align: left;
    }

    @media @wide {
      margin-top: 10rem;
      font-size: @font-size-h2;
    }

    @media @xwide {
      margin-top: 14rem;
      font-size: @font-size-h1;
    }
  }

  .search-form {
    color: @white;
    text-align: center;
    margin-top: 2rem;

    h2,
    h3,
    h4 {
      color: @white;
    }

    p {
      font-size: @font-size-small;
    }

    input {
      width: 100%;
      background: @white;
      border: none;
      .border-radius(3px);
      margin-bottom: 0.25em;
      padding: 0.5em;
    }

    @media @tablet {
      text-align: left;
    }

    @media @wide {
      text-align: left;
      margin-top: 4rem;
    }
  }
}

.logos {
  background: #fff;
  border-bottom: 1px solid #eee;
  padding: 1em;

  .view-content {
    .clearfix;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    div {
      display: inline-block;
      padding: 0 1em;

      img {
        width: 100%;
        height: auto;
        max-width: 60px;
      }
    }
  }

  @media @normal {
    padding: 2em;

    .view-content {
      flex-wrap: nowrap;

      div {
        img {
          max-width: 110px;
        }
      }
    }
  }
}

.hp-blocks {
  h2 {
    margin-bottom: 1em;

    @media @wide {
      margin-bottom: 2em;
    }
  }
}

.hp-block-item {
  text-align: center;
  margin-bottom: 2em;
  padding: 0 20px;

  .views-field-field-image {
    display: block;
    margin: 0 auto;
    background: @theme-color-1;
    text-align: center;
    width: 100px;
    height: 100px;
    padding-top: 25px;
    .border-radius(50px);
    .myanimated;

    img {
      display: inline;
      width: 50px;
      height: 50px;
    }
  }

  &:hover {
    .views-field-field-image {
      background: darken(@theme-color-1, 10%);
      .box-shadow(0 0 15px rgba(0, 0, 0, 0.33));
    }
  }

  .views-field-title {
    font-size: @font-size-h4;
    font-weight: 700;
    margin: 1em 0;

    a {
      color: @text-color;
    }
  }

  .views-field-field-link-to-page {
    margin-top: 1em;
    color: @theme-color-1;
    text-transform: uppercase;
    font-weight: 700;

    a {
      color: @theme-color-1;
    }
  }
}

.topimg {
  position: relative;
  //   margin-top: 51px;

  //   @media @normal {
  //     margin-top: 160px;
  //   }

  //   @media @wide {
  //     margin-top: 180px;
  //   }

  //   @media @xwide {
  //     margin-top: 190px;
  //   }

  .top-img-img {
    position: relative;
    z-index: 1;

    img {
      height: 160px;
      width: 100%;
      object-fit: cover;
      object-position: 0 center;
    }

    @media @tablet {
      img {
        object-position: center center;
      }
    }
  }

  h1 {
    position: absolute;
    z-index: 10;
    color: @theme-color-2;
    top: 60px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: none;
    padding: 0 @grid-gutter-width / 2;
    text-shadow: 0 0 10px @white, 0 0 20px @white, 0 0 30px @white;
  }
}

.sidebar {
  padding: 0;

  @media @normal {
    padding: 0 @grid-gutter-width / 2;
  }

  .well {
    padding: 0;
    border: none;
    margin: 0;
    background: none;
  }

  .sidebar-block,
  .cta-block {
    background: @gray-lighter;
    border: none;
    .border-radius(1em);
    padding: @grid-gutter-width;
    margin-bottom: 2em;

    input {
      width: 100%;
      margin-bottom: 0.5em;
      padding: 0.5em 1em;
      border: 1px solid @gray-light;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .cta-block {
    text-align: center;

    > p {
      a {
        .btn;
        .redbtn;
        .bigbtn;
      }
    }
  }
}

.team-block {
  margin-top: 2em;
}

.team-item {
  margin-bottom: 2em;
  padding: 0;

  .team-img-col {
    img {
      display: block;
      margin: 0 auto 1em auto;
      width: 120px;
      height: 120px;
      object-fit: contain;

      @media @wide {
        width: 100%;
        height: auto;
      }
    }
  }

  .team-text-col {
    h3 {
      margin-bottom: 0;
    }

    .team-email {
      margin-bottom: 0.5em;
      color: @gray-light;
    }
  }
}

.about-us-add {
  margin-top: 2em;
  border-top: 15px solid #eee;
  padding-top: 40px;

  &.partners {
    text-align: left;
  }

  h2 {
    text-align: left;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 320px;
    display: block;
    margin: 10px 0;
  }
}

.tools-block {
  margin: 1em 0;
  padding: 1em 0;

  @media @tablet {
    padding: 1em @grid-gutter-width / 2;
  }

  h2 {
    text-align: left;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 320px;
    display: block;
    margin: 0 auto;
  }

  &.tools-sale {
    img {
      margin: 0;
    }

    > p {
      a {
        .btn;
        .redbtn;
        .bigbtn;
      }
    }
  }

  &.tools-hire {
    img {
      margin: 0;
    }
  }

  &.tools-logo {
    display: inline-block;
    vertical-align: top;

    img {
      height: 60px;
      width: auto;
      max-width: 100%;
      margin: 0 auto;
    }

    h2 {
      text-align: center;
    }

    > p {
      a {
        .btn;
        .redbtn;
        display: inline-block;
        margin: 1em 0.25em 0 0.25em;
      }
    }
  }
}

.parts-request-form,
.contact-us-form {
  margin-top: 2em;

  form {
    padding: @grid-gutter-width;
    margin-top: 1em;
    .border-radius(1em);
    background: @gray-lightest;

    .form-item {
      padding: 0;

      @media @tablet {
        padding: 0 @grid-gutter-width / 2;
      }
    }

    .form-actions {
      @media @tablet {
        padding: 0 @grid-gutter-width / 2;
      }

      button {
        .btn;
        .bluebtn;
        .bigbtn;
      }
    }
  }
}

.suppliers-list-item {
  margin-bottom: 2em;

  .views-field-field-image {
    img {
      height: 60px;
      width: 100%;
      max-width: 240px;
      object-fit: contain;
      margin: 0 auto;
    }
  }

  .views-field-title {
    text-align: center;
  }

  .views-field-nothing {
    text-align: center;
    padding-top: 1em;
  }
}

.equipment-slider {
  > ul {
    img {
      width: 100%;
      height: auto;
    }
  }
}

.tips-list-item {
  margin-bottom: 2em;
}

.specials-block {
  margin: 2em 0 0 0;

  .view-content {
    .clearfix;

    .views-row {
      margin-bottom: 2em;

      .col-sm-3 {
        img {
          width: 100%;
          max-width: 220px;
          height: auto;
          display: inline;
        }
      }

      h2,
      h3,
      h4 {
        margin-top: 0;
      }
    }
  }
}

.specials-slider {
  margin-bottom: 2em;

  li {
    text-align: center;

    * {
      text-align: center;
    }

    img {
      display: inline-block;
    }
  }
}

#block-block-7 {
  margin-top: 20px;
  padding-top: 40px;
  border-top: 1px solid #eee;

  h2 {
    font-size: @font-size-h2;
  }
}

#block-menu-menu-information-2 {
  padding-top: 38px;
}

blockquote {
  font-size: @font-size-h3;
  font-style: italic;
  line-height: 25px;
  margin-left: 20px;
}

.team-block {
  margin-top: 40px;
  border-top: 15px solid #eee;
  padding-top: 30px;

  h2 {
    font-weight: 600;
    font-size: @font-size-h2;
    margin-bottom: 30px;
  }

  h3 {
    font-weight: 600;
  }

  .views-row {
    img {
      /*border:2px solid #eee;*/
    }
  }
}

#block-block-17 {
  h2 {
    margin: 10px 0;
  }
}

hr {
  border-top: 15px solid #eee;
}

.page-node-8 {
  .bx-wrapper ul li {
    margin: 0;
    padding: 0 0 0 42px !important;
  }
}

.suppliers-block {
  h2 {
    margin-bottom: 50px !important;
  }
}

.view-blog {
  border-top: 15px solid #eee;
  padding-top: 30px;

  .views-row {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
  }
}

.block-webform {
  .captcha {
    padding: 0 1rem;
    margin: 0 0 1rem 0;
  }
}

// Swap on Mobile
#contentblock {
  > .container {
    .sidebar {
      .well {
        box-shadow: none;
      }
    }

    .sidebar,
    .content-col {
      .sidebar-social-media {
        margin-bottom: 2em;
      }
    }

    @media all and (max-width: 991px) {
      display: flex;
      flex-wrap: wrap;
      .sidebar {
        order: 1;
      }
      .content-col {
        order: 0;
      }
    }
  }
}

.sidebar-social-media {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5em;

  &.align-left {
    justify-content: flex-start;
  }

  label {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  > a {
    font-size: 2em;
    line-height: 1;
    color: @theme-color-2;
    &:hover {
      color: @theme-color-1;
    }
  }
}

.header-social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media @normal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sidebar-social-media {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      line-height: 1;
      color: @theme-color-1;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      text-decoration: none;
      background-color: #fff;

      > i {
        line-height: 1;
      }

      &:hover {
        color: #fff;
        background-color: @theme-color-2;
      }
    }
  }
}

.topmenu {
  .region-topmenu {
    display: flex;
    flex-direction: column;
  }
  @media @normal {
    .region-topmenu {
      flex-direction: row;
    }
  }
}

.desktop-only {
  display: none !important;
  @media all and (min-width: 580px) {
    display: block !important;
  }
}

.mobile-only {
  @media all and (min-width: 580px) {
    display: none !important;
  }
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.animate__fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.topcarmenu {
  .carmenu {
    > ul {
      width: 100%;
      display: flex;
      flex-direction: row;

      > li {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.2;
        font-size: 1vw;
      }

      @media @xwide {
        > li {
          flex: 0 0 auto;
          font-size: 18px;
        }
      }
    }
  }
}
